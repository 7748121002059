import React from 'react';
import featureImg1 from "../../medias/feature-img1.png";
import featureImg2 from "../../medias/feature-img2.png";
import featureImg3 from "../../medias/feature-img3.png";
import featureImg4 from "../../medias/feature-img4.png";
import featureImg4Veeqo from "../../medias/feature-img4-veeqo.png";
import tikMark from "../../medias/tikVector.png";

const ShipStationFeaturesCard = () => {
    return (
        <div className="flex flex-wrap justify-between gap-y-[24px]">
            <div className="sm:max-w-[262px] max-w-full p-[12px] sm:mx-auto mx-[0]">
                <img src={featureImg1} alt="Stay in Sync" />
                <h3 className="text-[24px] font-semibold mt-[4px]">Stay in Sync</h3>
                <p className="text-[16px] text-gray-500 text-left">
                    Custom data is synced as orders come into ShipStation. Not on a
                    timer, not when you click a button; it's <i>automatic.</i>
                </p>
            </div>
            <div className="sm:max-w-[262px] max-w-full p-[12px] sm:mx-auto mx-[0]">
                <img src={featureImg2} alt="Import Images" width="48px" style={{borderRadius:'15px'}} />
                <h3 className="text-[24px] font-semibold mt-[4px]">
                    Import Images
                </h3>
                <p className="text-[16px] text-gray-500 text-left">
                    Import preview and user images:
                </p>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">On packing slips</p>
                </div>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">On-screen on ShipStation orders</p>
                </div>
                <br />
                <p className="text-[16px] text-gray-500 text-left">
                    On-screen images require the <u><a href="https://chromewebstore.google.com/detail/bkklbmeienplgdeanamiajbdeklmjilo" target="_blank">CustomSync Chrome extension.</a></u>
                </p>
            </div>
            <div className="sm:max-w-[262px] max-w-full p-[12px] sm:mx-auto mx-[0]">
                <img src={featureImg3} alt="Customizable" />
                <h3 className="text-[24px] font-semibold mt-[4px]">Customizable</h3>
                <p className="text-[16px] text-gray-500 text-left">
                    Sync custom data to:
                </p>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">Line Item Description</p>
                </div>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">Note from Buyer</p>
                </div>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">Internal Notes</p>
                </div>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">Custom Field 1, 2, or 3</p>
                </div>
                <br />
                <p className="text-[16px] text-gray-500 text-left">
                    Include/exclude specific properties:
                </p>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">
                        Item name
                    </p>
                </div>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">
                        Item quantity
                    </p>
                </div>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">
                        Specific customization fields
                    </p>
                </div>
            </div>
            <div className="sm:max-w-[262px] max-w-full p-[12px] sm:mx-auto mx-[0]">
                <img src={featureImg4} alt="Store Selection" />
                <h3 className="text-[24px] font-semibold mt-[4px]">
                    Store Selection
                </h3>
                <p className="text-[16px] text-gray-500 text-left">
                    Have multiple Amazon stores connected to ShipStation? Choose only
                    the stores you want to sync.
                </p>
            </div>
        </div>
    );
};

const VeeqoFeaturesCard = () => {
    return (
        <div className="flex flex-wrap justify-between gap-y-[24px]">
            <div className="sm:max-w-[262px] max-w-full p-[12px] sm:mx-auto mx-[0]">
                <img src={featureImg1} alt="Stay in Sync" />
                <h3 className="text-[24px] font-semibold mt-[4px]">Stay in Sync</h3>
                <p className="text-[16px] text-gray-500 text-left">
                    Automatically syncs every 5 minutes.
                </p>
                <br />
                <p className="text-[16px] text-gray-500 text-left">
                    Requires the <u><a href="https://chromewebstore.google.com/detail/ogconpfgpmhjaokoapjceapejadoceij" target="_blank">CustomSync Chrome extension.</a></u>
                </p>
            </div>
            <div className="sm:max-w-[262px] max-w-full p-[12px] sm:mx-auto mx-[0]">
                <img src={featureImg2} alt="Import Images" width="48px" style={{borderRadius:'15px'}} />
                <h3 className="text-[24px] font-semibold mt-[4px]">
                    Import Images
                </h3>
                <p className="text-[16px] text-gray-500 text-left">
                    Import preview and user images:
                </p>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">On packing slips</p>
                </div>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">On-screen on Veeqo orders</p>
                </div>
                <br />
                <p className="text-[16px] text-gray-500 text-left">
                    Easily verify customer personalization!
                </p>
            </div>
            <div className="sm:max-w-[262px] max-w-full p-[12px] sm:mx-auto mx-[0]">
                <img src={featureImg3} alt="Customizable" />
                <h3 className="text-[24px] font-semibold mt-[4px]">Customizable</h3>
                <p className="text-[16px] text-gray-500 text-left">
                    Sync custom data to:
                </p>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">Item Notes</p>
                </div>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">Customer Notes</p>
                </div>
                <br />
                <p className="text-[16px] text-gray-500 text-left">
                    Include/exclude specific properties:
                </p>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">
                        Item name
                    </p>
                </div>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">
                        Item quantity
                    </p>
                </div>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">
                        Specific customization fields
                    </p>
                </div>
            </div>
            <div className="sm:max-w-[262px] max-w-full p-[12px] sm:mx-auto mx-[0]">
                <img src={featureImg4Veeqo} alt="Packing Slips" />
                <h3 className="text-[24px] font-semibold mt-[4px]">
                    Packing Slips
                </h3>
                <p className="text-[16px] text-gray-500 text-left">
                    Formatted for packing slips and invoices:
                </p>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">
                        List format for Item Notes
                    </p>
                </div>
                <div className="flex items-center gap-[8px]">
                    <img src={tikMark} alt="tik mark" />
                    <p className="text-[16px] text-gray-500">
                        List format for Customer Notes
                    </p>
                </div>
            </div>
        </div>
    );
};

export {
    ShipStationFeaturesCard,
    VeeqoFeaturesCard
}
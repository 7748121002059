import React from "react";
import HoursImage from "../../medias/hours.png";
import OrdersImage from "../../medias/ordersImg.png";
import StarImage from "../../medias/starImage.png";

import { motion } from 'framer-motion';

const variants = {
  visible: (custom) => ({
    transition: { delay: custom * 0.2 }
  })
}

const Companies = () => {
  return (
    <div className="mx-auto max-w-[1120px]  px-[24px] sm:px-0 md:mt-[350px] sm:mt-[250px] mt-[200px]">
      <motion.div
        initial={{ opacity: 0, y: 80 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.75 }}
        viewport={{ once: true, amount: 0.2 }}
      >
        <p className="sm:text-[48px] text-[42px] text-center mb-[64px]">
          Save{" "}<span className="text-[#1387f2]">{" "}Time.<br />{" "}</span>{" "}
          Increase{" "}<span className="text-[#1387f2]">{" "}Efficiency.<br />{" "}</span>{" "}
        </p>
      </motion.div>

      <div className="mx-auto flex flex-wrap justify-between bg[#5BE1C6] gap-y-[75px]">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.75 }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <div className=" mx-auto text-center w-[353px]">
            <img className="mx-auto" src={HoursImage} alt="" />

            <p className="text-[24px] mt-[24px]">
              Save
            </p>

              <div className="flex justify-center">
                <p className="text-[48px] font-bold">20</p>
                <p className="text-[#F29E13] text-[32px] font-medium">+</p>
              </div>
            
            <p className="text-[24px] mb-[24px]">
              Hours Per Week
            </p>
          </div>
        </motion.div>
        {/* ---- */}
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.75 }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <div className="mx-auto text-center w-[353px]">
            <img className="mx-auto" src={OrdersImage} alt="" />

            <p className="text-[24px] mt-[24px]">Over</p>

            <div className="flex justify-center">
              <p className="text-[48px] font-bold">45</p>
              <p className="text-[#F29E13] text-[32px] font-medium">K</p>
            </div>
          
            <p className="text-[24px] mb-[24px]">Orders Synced per Month</p>
          </div>
        </motion.div>
        {/* ---- */}
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.75 }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <div className="mx-auto text-center w-[353px]">
            <img className="mx-auto" src={StarImage} alt="" />

            <p className="text-[24px] mt-[24px]">
              Over
            </p>

            <div className="flex justify-center">
              <p className="text-[48px] font-bold">90</p>
              <p className="text-[#F29E13] text-[32px] font-medium">%</p>
            </div>
          
            <p className="text-[24px] mb-[24px]">
              User Retention
            </p>
            
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Companies;

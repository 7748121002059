import React from "react";
import HeroImg from "../../medias/hero.png";
import HeroBottomSection from "../../medias/heroButtomImg.png";

const Banner = () => {
  return (
    <div className="bg-gradient-to-b from-[#3B79B3] to-[#19344D] md:max-h-[1050px] max-h-[1200px]">
      <div className="md:flex justify-center items-center">
        <div className="px-[24px]">
          <p className="lg:text-[60px] text-[45px] leading-[70px] max-w-[587px] font-bold text-white">
            <span className="text-[#F29E13]">Sync</span> your customization and
            <span className="text-[#F29E13]"> images</span> from
            <span className="text-[#F29E13]"> Amazon</span> to ShipStation and
            Veeqo
          </p>
          <p className="text-[18px] text-white max-w-[365px] mt-[25px] mb-[20px]">
            Custom order data syncs <i><u>automatically.</u></i>
            <br/>
            View images on-screen and on packing slips.
            <br/>
            No more copy and pasting!
          </p>
      
          <a href="https://customsync.app/login?signup=true">
            <div className=" cursor-pointer text-white text-nowrap bg-[#F29E13] px-[16px] py-[8px] rounded-lg font-bold orange-button" style={{width:'fit-content'}}>
              Start Your Free Trial
            </div>
          </a>
        </div>
        <div>
          <img src={HeroImg} alt="" />
        </div>
      </div>
      <img className="mx-auto" src={HeroBottomSection} alt="" />
    </div>
  );
};

export default Banner;

import React from "react";

const Amazon = () => {

  return (
    <div className="mx-auto max-w-[1392px] ">
      <div className=" bg-[#F29E13] rounded-[25px] pb-[40px] pt-[40px] px-[12px]  sm:px-0 mt-[100px] mx-[20px]">
        <div className="flex justify-center ">
          <iframe style={{width:'80%'}} width="800" height="450" src="https://www.youtube.com/embed/daUUpP7Oyqw?si=P_uoRTEiBSGkkGZs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  );
};

export default Amazon;

import React from "react";
import websiteLogo from "../medias/website-footer-logo.png";

const Footer = () => {
  return (
    <div className="bg-[#313131] py-[40px] pt-[120px]">
      <div className="text-center">
        <div className="flex-col justify-center justify-center mb-[15px]">
          <img className="mx-auto" src={websiteLogo} alt="website Logo" style={{height: '40px'}} />
          <p className="text-[24px] font-bold text-white">CustomSync</p>
        </div>
        <p className="text-[16px] text-[#BABABA]">
          Sync your customization from Amazon to ShipStation and Veeqo
        </p>
        <div className="text-[16px] text-white font-bold flex gap-[16px]  justify-center justify-center my-[5px]">
          <a href="https://bluewarelabs.com" target="_blank">About</a>
          <a href="https://bluewarelabs.com/contact" target="_blank">Contact</a>
          <a href="https://bluewarelabs.com/privacy" target="_blank">Privacy Policy</a>
          <a href="https://customsync.app/login">Login</a>
        </div>
        <p className="text-[#BABABA] text-[16px]">
          © 2024 <span className="text-[#1387f2]"><a href="https://bluewarelabs.com" target="_blank">BlueWare Labs</a></span>. All
          rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
